import { Menu } from 'antd';
import React, { useContext } from 'react';
import {
  AppstoreOutlined,
  WindowsOutlined,
  TeamOutlined,
  PicCenterOutlined,
  FilePdfOutlined,
  IdcardOutlined,
  CheckOutlined,
  FormOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CommonStore from '../../store/CommonStore';
import { Link, useLocation } from 'react-router-dom';

const { Item } = Menu;

const NavigationBar: React.FC<{ theme: 'dark' | 'light' }> = ({ theme }) => {
  //
  const { t } = useTranslation();

  //
  const storeCommon = useContext(CommonStore);

  //
  const location = useLocation();

  //
  const menu = [
    {
      key: 'block-info',
      label: t('Blocks information'),
      icon: <PicCenterOutlined />,
    },
    {
      key: 'main-items',
      label: t('Main block cards'),
      icon: <IdcardOutlined />,
    },
    {
      key: 'partners',
      label: t('Partners'),
      icon: <WindowsOutlined />,
    },
    {
      key: 'team-items',
      label: t('Team cards'),
      icon: <TeamOutlined />,
    },
    {
      key: 'app-items',
      label: t('Application cards'),
      icon: <AppstoreOutlined />,
    },
    {
      key: 'brochures-items',
      label: t('Brochures cards'),
      icon: <FilePdfOutlined />,
    },
    {
      key: 'mission-items',
      label: t('Mission block cards'),
      icon: <CheckOutlined />,
    },
    {
      key: 'contact',
      label: t('Contact page'),
      icon: <FormOutlined />,
    },
    {
      key: 'emails',
      label: t('Emails'),
      icon: <MailOutlined />,
    },
    // {
    //   key: 'who',
    //   label: t('Who we are'),
    //   icon: <QuestionOutlined />,
    // },
    // {
    //   key: 'mission',
    //   label: t('Our mission'),
    //   icon: <ExclamationCircleOutlined />,
    // },
    // {
    //   key: 'team',
    //   label: t('Our team'),
    //   icon: <TeamOutlined />,
    // },
    // {
    //   key: 'applications',
    //   label: t('Applications'),
    //   icon: <DesktopOutlined />,
    // },
    // {
    //   key: 'brochures',
    //   label: t('Вrochures'),
    //   icon: <DownloadOutlined />,
    // },
  ];

  const activeRoute = location.pathname.split('/')[1] || '';

  return (
    <Menu
      id={`menu-${storeCommon.user?.id}`}
      style={{ width: 256 }}
      mode='inline'
      theme={theme}
      defaultSelectedKeys={[activeRoute]}
      key={`menu-${activeRoute}`}
    >
      {storeCommon.user &&
        menu.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Item icon={item.icon} key={item.key}>
                <Link to={`/${item.key}`}>{item.label}</Link>
              </Item>
            </React.Fragment>
          );
        })}
    </Menu>
  );
};

export default NavigationBar;
