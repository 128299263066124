import { Result } from 'antd';
import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

const BlockInfo = lazy(() => import('../pages/BlockInfo'));
const EditBlockInfo = lazy(() => import('../pages/BlockInfo/EditBlockInfo'));
const AppItems = lazy(() => import('../pages/AppItems'));
const EditAppItems = lazy(() => import('../pages/AppItems/EditAppItems'));
const TeamItems = lazy(() => import('../pages/TeamItems'));
const EdiTeamItems = lazy(() => import('../pages/TeamItems/EditTeamItems'));

const BrochuresItems = lazy(() => import('../pages/BrochuresItems'));
const EdiBrochuresItems = lazy(
  () => import('../pages/BrochuresItems/EditBrochuresItems')
);

const MainItems = lazy(() => import('../pages/MainItems'));
const EditMainItem = lazy(() => import('../pages/MainItems/EditMainItem'));

const Partners = lazy(() => import('../pages/Partners'));
const EditPartners = lazy(() => import('../pages/Partners/EditPartners'));

const MissionItems = lazy(() => import('../pages/MissionItems'));
const EditMissionItem = lazy(
  () => import('../pages/MissionItems/EditMissionItem')
);

const Contact = lazy(() => import('../pages/Contact'));
const EditContact = lazy(() => import('../pages/Contact/EditContact'));
const Emails = lazy(() => import('../pages/Emails'));

const AppRouter = () => {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path='/block-info' element={<BlockInfo />} />
        <Route path='/block-info/:blockInfoId' element={<EditBlockInfo />} />
        <Route path='/app-items' element={<AppItems />} />
        <Route path='/app-items/:appItemId' element={<EditAppItems />} />
        <Route path='/app-items/create' element={<EditAppItems />} />
        <Route path='/team-items' element={<TeamItems />} />
        <Route path='/team-items/:teamItemId' element={<EdiTeamItems />} />
        <Route path='/team-items/create' element={<EdiTeamItems />} />
        <Route path='/brochures-items' element={<BrochuresItems />} />
        <Route
          path='/brochures-items/:brochuresItemId'
          element={<EdiBrochuresItems />}
        />
        <Route path='/brochures-items/create' element={<EdiBrochuresItems />} />
        <Route path='/main-items' element={<MainItems />} />
        <Route path='/main-items/:mainItemId' element={<EditMainItem />} />
        <Route path='/main-items/create' element={<EditMainItem />} />
        <Route path='/partners' element={<Partners />} />
        <Route path='/partners/:partnerId' element={<EditPartners />} />
        <Route path='/partners/create' element={<EditPartners />} />
        <Route path='/mission-items' element={<MissionItems />} />
        <Route
          path='/mission-items/:missionItemId'
          element={<EditMissionItem />}
        />
        <Route path='/mission-items/create' element={<EditMissionItem />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/contact/:contactId' element={<EditContact />} />
        <Route path='/emails' element={<Emails />} />
        {/* <Route path='/partners' element={<Partners />} /> */}
        {/* <Route path='/main' element={<Main />} />
        <Route path='/main/:mainId' element={<EditMain />} />
        <Route path='/who' element={<Who />} />
        <Route path='/who/:whoId' element={<EditWho />} />
        <Route path='/mission' element={<Mission />} />
        <Route path='/mission/:missionId' element={<EditMission />} />
        <Route path='/team' element={<Team />} />
        <Route path='/Applications' element={<Applications />} />
        <Route path='/brochures' element={<Вrochures />} /> */}
        <Route element={() => <Result status='404' title='404' />} />;
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
