import { lazy, Suspense } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { Col, Layout, Row } from 'antd';
import logo from '../assets/img/logo.svg';

const Login = lazy(() => import('../pages/Login'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));

const GuestRouter = () => {
  return (
    <Suspense fallback={null}>
      <Layout style={{ minHeight: '100vh' }}>
        <Layout.Content>
          <Row
            justify={'center'}
            align={'middle'}
            style={{ minHeight: '100vh' }}
          >
            <Col span={6} style={{ minWidth: '450px' }}>
              <h1>
                <Link
                  to='/'
                  style={{
                    display: 'flex',
                    margin: '0 auto 25px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <h3>Admin</h3>
                    <img src={logo} alt='logo' width='220px' />
                  </div>
                </Link>
              </h1>
              <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/login' element={<Login />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route
                  path='/reset-password/:token'
                  element={<ResetPassword />}
                />
                <Route element={<Login />} />
                {/* <Route path='/sign-up' exact render={() => <SignUp />} /> */}
                {/* <Route path='/invite/:token' exact render={() => <Invite />} />; */}
              </Routes>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </Suspense>
  );
};

export default GuestRouter;
